@import "bootstrap";

/* Percentage width calculator from site width */
/* Grid System Calculation */
/* #sidebar { width: grid-width(5); } */
/* Border radius */
/* parisienne-regular - latin */
@font-face {
    font-family: 'Parisienne';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/parisienne/parisienne-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/parisienne/parisienne-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/parisienne/parisienne-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/parisienne/parisienne-v13-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/parisienne/parisienne-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/parisienne/parisienne-v13-latin-regular.svg#Parisienne') format('svg'); /* Legacy iOS */
}


/* lato-300 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/lato/lato-v23-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/lato/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato/lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato/lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato/lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato/lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato/lato-v23-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/lato/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato/lato-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato/lato-v23-latin-700.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato/lato-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato/lato-v23-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}


/* @include border-radius(10px, $webkit: false); */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
}

audio[controls], canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

html {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
    font-size: 62.5%;
    line-height: 1.231;
}

body, button, input, select, textarea {
    font-family: sans-serif;
    color: #222;
}

::-moz-selection {
    background: #8C262A;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #8C262A;
    color: #fff;
    text-shadow: none;
}

a {
    color: #8C262A;
}

a:visited {
    color: #8C262A;
}

a:focus {
    outline: thin dotted;
}

a:hover, a:active {
    outline: 0;
    color: #8C262A;
}

b, strong {
    font-weight: bold;
}

small {
    font-size: 85%;
}

ul, ol {
    margin: 1em 0;
    padding: 0 0 0 40px;
}

dd {
    margin: 0 0 0 40px;
}

nav ul, nav ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    border: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    border: 0;
    *margin-left: -7px;
    padding: 0;
}

label {
    cursor: pointer;
}

button, input, select, textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
}

button, input {
    line-height: normal;
    *overflow: visible;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
}

input[type="search"] {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Contain floats: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

body {
    font-family: "Lato", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-size: 1.6rem;
    line-height: 1.9rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
}

.site {
    max-width: 940px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

header {
    background: url(../img/header-bg.jpg) no-repeat center top;
    min-height: 540px;
    width: 100%;
}

header.subpage {
    width: 100%;
    height: 100px;
    min-height: 100px;
}

.topbar {
    max-width: 940px;
    margin: 0 auto;
    padding: 15px 0;
    height: 100px;
    zoom: 1;
}

.topbar:before, .topbar:after {
    content: "";
    display: table;
}

.topbar:after {
    clear: both;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    #branding {
        margin-left: 20px;
    }
}

.main-nav {
    padding-top: 25px;
}

.main-nav li {
    list-style: none;
    float: left;
    margin-right: 40px;
    font-size: 16px;
    line-height: 19px;
    font-size: 1.6rem;
    line-height: 1.9rem;
}

.main-nav li:last-child {
    margin-right: 0;
}

.main-nav li > a {
    color: #fff;
}

.main-nav li > a:visited {
    color: #fff;
}

#toggle {
    display: block;
    width: 28px;
    position: absolute;
    right: -20px;
    top: 25px;
    padding-top: 10px;
    height: 30px;
    text-decoration: none;
    border-bottom: 0;
}

#toggle span:after,
#toggle span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
}

#toggle span:after {
    top: 9px;
}

#toggle span {
    position: relative;
    display: block;
}

#toggle span,
#toggle span:after,
#toggle span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
}

#mobile-nav {
    position: relative;
    z-index: 9999;
    width: 230px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    top: 90px;
    right: 20px;
}

#mobile-nav ul > li {
    font-size: 15px;
    line-height: 17px;
    font-size: 1.5rem;
    line-height: 1.7rem;
    background: none;
    text-transform: uppercase;
    padding: 8px 0;
    background: none;
    margin-bottom: 0;
}

#mobile-nav ul > li:last-child {
    border-bottom: none;
}

#mobile-nav ul > li > a {
    border-bottom: none;
    display: block;
    padding: 10px 5px;
}

#mobile-nav ul > li > a:hover, #mobile-nav ul > li > a.active {
    color: #c8363c;
    text-decoration: none;
}

.teaser-text {
    margin-top: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    text-align: center;
}

.teaser-text > h2 {
    font-family: "Parisienne", cursive;
    font-size: 40px;
    line-height: 54px;
    font-size: 4rem;
    line-height: 5.4rem;
    color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .teaser-text > h2 {
        font-size: 36px;
        line-height: 40px;
        font-size: 3.6rem;
        line-height: 4rem;
    }
}

.teaser-text .btn {
    background: #B0181E;
    color: #fff;
    font-weight: 100;
    border-radius: 2px;
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
    font-size: 18px;
    line-height: 22px;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 18px 20px;
}

.teaser-info {
    max-width: 500px;
    margin: 0 auto;
    color: #fff;
    zoom: 1;
}

.teaser-info:before, .teaser-info:after {
    content: "";
    display: table;
}

.teaser-info:after {
    clear: both;
}

.teaser-info > .col, .teaser-info .divider {
    float: left;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .teaser-info > .col, .teaser-info .divider {
        float: none;
    }
}

.teaser-info .divider {
    width: 12px;
    height: 57px;
    background: url(../img/divider.png);
    margin: 0 20px;
}

.teaser-info p {
    font-size: 18px;
    line-height: 22px;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 0;
    padding-top: 10px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .teaser-info .divider {
        display: none;
    }
}

#main {
    color: #1B1A1A;
    font-weight: 300;
    line-height: 1.5em;
}

ul {
    padding-left: 20px;
}

ul li {
    list-style-type: circle;
}

.headline {
    text-align: center;
    margin: 50px 0 30px 0;
}

.headline.text-left {
    text-align: left;
}

.headline > h3 {
    margin: 0;
    font-weight: 300;
    color: rgba(26, 25, 25, 0.5);
    font-size: 18px;
    line-height: 22px;
    font-size: 1.8rem;
    line-height: 2.2rem;
}

.headline > h2 {
    font-weight: 400;
    margin: 0;
    color: #8C262A;
    font-size: 22px;
    line-height: 27px;
    font-size: 2.2rem;
    line-height: 2.7rem;
}

h3 {
    font-size: 18px;
    line-height: 21px;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #8C262A;
}

h4 {
    font-size: 17px;
    line-height: 19px;
    font-size: 1.7rem;
    line-height: 1.9rem;
    color: #8C262A;
}

.map {
    width: 100%;
    margin-bottom: 20px;
}

form .btn {
    background: #B0181E;
    color: #fff;
    font-weight: 100;
    border-radius: 2px;
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
    font-size: 16px;
    line-height: 19px;
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 10px 14px;
}

.products {
    background: #8C262A;
    width: 100%;
    min-height: 320px;
    padding-bottom: 30px;
    margin-top: 40px;
}

.products hgroup > h3, .products hgroup h2 {
    color: #fff;
}

.products figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 100%;
    max-height: 140px;
    max-width: 220px;
    width: 48%;
    text-align: center;
    cursor: pointer;
}

.products figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.products figure figcaption {
    padding: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.products figure figcaption::before, .products figure figcaption::after {
    pointer-events: none;
}

.products figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.products figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    font-size: 1.8rem;
    line-height: 1.9rem;
    margin: 55px 0 0;
}

.products figure h2 span {
    font-weight: 800;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .products figure h2 {
        margin-top: 30px;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    figure.effect-lily {
        min-width: 120px;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    figure.effect-lily {
        min-width: 150px;
    }
}

figure.effect-lily img {
    max-width: none;
    opacity: 0.6;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-lily figcaption {
    text-align: left;
}

figure.effect-lily figcaption > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    height: 50%;
}

figure.effect-lily h2 {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
}

figure.effect-lily:hover img {
    opacity: 0.85;
}

figure.effect-lily:hover img {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

figure.effect-lily:hover p {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
}

.function {
    padding: 0;
    zoom: 1;
}

.function:before, .function:after {
    content: "";
    display: table;
}

.function:after {
    clear: both;
}

.function li {
    float: left;
    list-style: none;
    margin-right: 30px;
}

.function li:last-child {
    margin-right: 0;
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
    .function li {
        margin-right: 0;
        margin-bottom: 10px;
        width: 33%;
    }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .function li {
        margin-right: 2%;
        margin-bottom: 10px;
        width: 45%;
    }
}

.sketch {
    width: 100px;
}

.sketch figcaption {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    word-break: break-word;
}

/*! http://responsiveslides.com v1.54 by @viljamis */
.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 20px 0;
}

.rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
}

.rslides li:first-child {
    position: relative;
    display: block;
    float: left;
}

.rslides img {
    display: block;
    float: left;
    width: 100%;
    border: 0;
}

.rslides_tabs {
    zoom: 1;
    text-align: center;
    padding-left: 0;
}

.rslides_tabs:before, .rslides_tabs:after {
    content: "";
    display: table;
}

.rslides_tabs:after {
    clear: both;
}

.rslides_tabs li {
    display: inline;
    list-style: none;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-right: 5px;
}

.rslides_tabs li.rslides_here > a {
    background: #8C262A;
}

.rslides_tabs li.rslides_here > a:hover {
    background: #641b1e;
}

.rslides_tabs li > a {
    width: 25px;
    display: inline-block;
    padding: 4px 0;
    background: rgba(26, 26, 26, 0.5);
    color: #fff;
    border-radius: 50px;
}

.rslides_tabs li > a:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.5);
}

footer {
    font-size: 14px;
    line-height: 17px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding: 30px 0;
}

footer h5 {
    color: #8C262A;
    font-weight: 600;
    margin-top: 0;
}

footer.alternate {
    border-top: 1px solid rgba(26, 25, 25, 0.5);
    margin-top: 20px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    footer {
        text-align: center;
    }

    footer h5 {
        margin-top: 20px;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    footer {
        text-align: center;
    }

    footer h5 {
        margin-top: 20px;
    }
}

.footer-nav {
    zoom: 1;
}

.footer-nav:before, .footer-nav:after {
    content: "";
    display: table;
}

.footer-nav:after {
    clear: both;
}

.footer-nav li {
    float: left;
    margin-right: 40px;
    list-style: none;
}

.footer-nav li:last-child {
    margin-right: 0;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .footer-nav li {
        float: none;
        margin: 0;
        margin-bottom: 10px;
    }

    .footer-nav li a {
        background: #B0181E;
        color: #fff;
        font-weight: 100;
        border-radius: 2px;
        -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
        padding-top: 25px;
        font-size: 16px;
        line-height: 19px;
        font-size: 1.6rem;
        line-height: 1.9rem;
        width: 100%;
        display: block;
        text-align: center;
        height: 68px;
    }
}

/*# sourceMappingURL=style.css.map */
